import { Providers } from './providers'
import { Router } from './router'
import './scss/main.scss'
import { MixPanel } from './services/mixpanelActions'

declare global {
    interface Window {
        gtag_report_conversion: () => void
        gtag_report_form_submit: () => void
        gtag: (...args: string[]) => void
        dataLayer: any[]
        hbspt: {
            forms: {
                // eslint-disable-next-line no-empty-pattern
                create: ({
                    portalId,
                    formId,
                    target,
                    onFormSubmit,
                    onFormReady,
                }: {
                    portalId: string | undefined
                    formId: string | undefined
                    target: string
                    onFormSubmit: (() => void) | undefined
                    onFormReady: ((form: HTMLFormElement) => void) | undefined
                }) => void
            }
        }
    }
}

MixPanel.actions.init()

function App() {
    return (
        <Providers>
            <Router />
        </Providers>
    )
}

export default App
